// src/components/Login.js

/* ============================================================
   Import Statements
============================================================ */

import React, { useState } from 'react';
import { COMPANY_NAME } from '../../config.js';

// Firebase Authentication Functions
import {
  signInWithEmailAndPassword,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail, // Import this function
  GoogleAuthProvider,
  signInWithPopup,
} from 'firebase/auth';
import { auth } from '../../firebase';

// Services
import { studentLogin } from './studentAuthService.js';
import { fetchLinkedStudents } from './ParentLogin';

// Assets
import logo from '../../assets/logo/logo.png';
import googleLogo from '../../assets/logo/google-logo.png';

// CSS
import './Login.css';
import '../../styles/global.css';

/* ============================================================
   Login Component
============================================================ */

function Login({ onClose }) {
  /* ============================================================
     State Variables
  ============================================================ */

  const [isSignUp, setIsSignUp] = useState(true);
  const [showStudentLogin, setShowStudentLogin] = useState(false);
  const [showParentLogin, setShowParentLogin] = useState(false);
  const [showForgotPassword, setShowForgotPassword] = useState(false); // New state variable

  const [schoolName, setSchoolName] = useState('');
  const [emailOrUsername, setEmailOrUsername] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [error, setError] = useState('');
  const [message, setMessage] = useState(''); // New state for success messages
  const [linkedStudents, setLinkedStudents] = useState([]);

  /* ============================================================
     Event Handlers and Helper Functions
  ============================================================ */

  const handleFormToggle = () => {
    setIsSignUp(!isSignUp);
    setError('');
    setMessage('');
  };

  const formatFirebaseError = (message) => {
    return message.replace('Firebase:', '').replace(/[\(\)]/g, '').trim();
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (isSignUp && password !== confirmPassword) {
      setError('Passwords do not match');
      setTimeout(() => setError(''), 2000);
      return;
    }

    try {
      if (isSignUp) {
        const userCredential = await createUserWithEmailAndPassword(
          auth,
          emailOrUsername,
          password
        );
        console.log('User signed up:', userCredential.user);
        setError('');
      } else {
        const userCredential = await signInWithEmailAndPassword(
          auth,
          emailOrUsername,
          password
        );
        console.log('User logged in:', userCredential.user);
        setError('');
      }
    } catch (error) {
      console.error('Error:', error.message);
      setError(formatFirebaseError(error.message));
      setTimeout(() => setError(''), 2000);
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      console.log('Google sign-in successful:', result.user);
      setError('');
    } catch (error) {
      console.error('Error with Google sign-in:', error.message);
      setError(formatFirebaseError(error.message));
      setTimeout(() => setError(''), 2000);
    }
  };

  const handleStudentLoginSubmit = async (e) => {
    e.preventDefault();

    try {
      const user = await studentLogin(schoolName, emailOrUsername, password);
      console.log('Student logged in:', user);
      setError('');
    } catch (error) {
      console.error('Error:', error.message);
      setError('Student login failed. Please check your credentials.');
    }
  };

  const handleParentLoginSubmit = async (e) => {
    e.preventDefault();
    try {
      console.log('Fetching linked students with:', { schoolName, emailOrUsername });
      const students = await fetchLinkedStudents(schoolName, emailOrUsername);
      if (students.length === 0) {
        setError('No students found for the provided email and school.');
      } else {
        setLinkedStudents(students);
        setError('');
      }
    } catch (err) {
      setError('An error occurred while fetching students.');
      console.error(err);
    }
  };

  const handleStudentSelection = async (student) => {
    try {
      const userCredential = await signInWithEmailAndPassword(
        auth,
        student.studentData.studentEmail,
        'password123' // Use the password stored in your system
      );
      console.log(`Logged in as student: ${student.studentName}`);
    } catch (error) {
      console.error('Error logging into student account:', error);
      setError('Failed to log into student account.');
    }
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const toggleConfirmPasswordVisibility = () => {
    setConfirmPasswordVisible(!confirmPasswordVisible);
  };

  // New function to handle password reset
  const handleForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    setError('');
    setMessage('');

    try {
      await sendPasswordResetEmail(auth, emailOrUsername);
      setMessage('Password reset email sent. Please check your inbox.');
    } catch (error) {
      console.error('Error sending password reset email:', error);
      setError(formatFirebaseError(error.message));
    }
  };

  /* ============================================================
     Render Component
  ============================================================ */

  return (
    <div
      className={`login-container ${
        showStudentLogin
          ? 'student-active'
          : showParentLogin
          ? 'parent-active'
          : showForgotPassword
          ? 'forgot-password-active'
          : ''
      }`}
      onClick={onClose}
    >
      <div className="container" onClick={(e) => e.stopPropagation()}>
        <div className="left-panel">
          <div className="brand">
            <img src={logo} alt="Naxos.ai Logo" />
            <h1>
              {showStudentLogin
                ? 'Student Login'
                : showParentLogin
                ? 'Parent Login'
                : showForgotPassword
                ? 'Forgot Password'
                : isSignUp
                ? 'Sign Up'
                : 'Welcome Back!'}
            </h1>
          </div>

          {/* Conditionally Render Forms */}
          {showForgotPassword ? (
            <>
              <div className="login-options">
                <p className="form-toggle-text">
                  <a href="#" onClick={() => setShowForgotPassword(false)}>
                    ← Back to Login
                  </a>
                </p>
              </div>
              <form onSubmit={handleForgotPasswordSubmit}>
                <div className="input-group">
                  <input
                    type="email"
                    placeholder="Enter your email"
                    value={emailOrUsername}
                    onChange={(e) => setEmailOrUsername(e.target.value)}
                    required
                  />
                </div>
                <button type="submit" className="login-button">
                  Send Password Reset Email
                </button>
              </form>
            </>
          ) : showStudentLogin ? (
            <>
              <div className="login-options">
                <p className="form-toggle-text">
                  <a href="#" onClick={() => setShowStudentLogin(false)}>
                    ← Back
                  </a>
                </p>
                <p className="form-toggle-text">
                  <a
                    href="#"
                    onClick={() => {
                      setShowParentLogin(true);
                      setShowStudentLogin(false);
                    }}
                  >
                    Login as Parent
                  </a>
                </p>
              </div>
              <form onSubmit={handleStudentLoginSubmit}>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="School Name"
                    value={schoolName}
                    onChange={(e) => setSchoolName(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Username"
                    value={emailOrUsername}
                    onChange={(e) => setEmailOrUsername(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group">
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span className="icon" onClick={togglePasswordVisibility}>
                    {passwordVisible ? 'Hide' : 'Show'}
                  </span>
                </div>
                <button type="submit" className="login-button">
                  Log in as Student
                </button>
                <p className="forgot-password-text">
                  <a href="#" onClick={() => setShowForgotPassword(true)}>
                    Forgot Password?
                  </a>
                </p>
              </form>
            </>
          ) : showParentLogin ? (
            <>
              <div className="login-options">
                <p className="form-toggle-text">
                  <a
                    href="#"
                    onClick={() => {
                      setShowParentLogin(false);
                      setShowStudentLogin(false);
                    }}
                  >
                    ← Back
                  </a>
                </p>
                <p className="form-toggle-text">
                  <a
                    href="#"
                    onClick={() => {
                      setShowParentLogin(false);
                      setShowStudentLogin(true);
                    }}
                  >
                    Student Login
                  </a>
                </p>
              </div>
              <form onSubmit={handleParentLoginSubmit}>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="School Name"
                    value={schoolName}
                    onChange={(e) => setSchoolName(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group">
                  <input
                    type="email"
                    placeholder="Parent Email"
                    value={emailOrUsername}
                    onChange={(e) => setEmailOrUsername(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group">
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder="Password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span className="icon" onClick={togglePasswordVisibility}>
                    {passwordVisible ? 'Hide' : 'Show'}
                  </span>
                </div>
                <button type="submit" className="login-button">
                  Log in as Parent
                </button>
                <p className="forgot-password-text">
                  <a href="#" onClick={() => setShowForgotPassword(true)}>
                    Forgot Password?
                  </a>
                </p>
              </form>

              {/* Display linked students as buttons after successful parent login */}
              {linkedStudents.length > 0 && (
                <div>
                  <h3>Select an Enrolled Student</h3>
                  {linkedStudents.map((student, index) => (
                    <button
                      key={index}
                      onClick={() => handleStudentSelection(student)}
                      className="student-button"
                    >
                      {student.studentName}
                    </button>
                  ))}
                </div>
              )}
            </>
          ) : (
            <>
              <p className="form-toggle-text">
                {isSignUp ? (
                  <>
                    Already have an account?{' '}
                    <a href="#" onClick={handleFormToggle}>
                      Log in
                    </a>
                  </>
                ) : (
                  <>
                    Don't have an account?{' '}
                    <a href="#" onClick={handleFormToggle}>
                      Sign up
                    </a>
                  </>
                )}
              </p>
              <form onSubmit={handleSubmit}>
                <div className="input-group">
                  <input
                    type="text"
                    placeholder="Email"
                    value={emailOrUsername}
                    onChange={(e) => setEmailOrUsername(e.target.value)}
                    required
                  />
                </div>
                <div className="input-group">
                  <input
                    type={passwordVisible ? 'text' : 'password'}
                    placeholder="Password"
                    id="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                  />
                  <span className="icon" onClick={togglePasswordVisibility}>
                    {passwordVisible ? 'Hide' : 'Show'}
                  </span>
                </div>
                {isSignUp && (
                  <div className="input-group">
                    <input
                      type={confirmPasswordVisible ? 'text' : 'password'}
                      placeholder="Confirm Password"
                      id="confirm-password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                    />
                    <span className="icon" onClick={toggleConfirmPasswordVisibility}>
                      {confirmPasswordVisible ? 'Hide' : 'Show'}
                    </span>
                  </div>
                )}
                <button type="submit" className="login-button">
                  {isSignUp ? 'Sign up' : 'Log in'}
                </button>
                {!isSignUp && (
                  <p className="forgot-password-text">
                    <a href="#" onClick={() => setShowForgotPassword(true)}>
                      Forgot Password?
                    </a>
                  </p>
                )}
              </form>
              <button className="google-signin-button" onClick={handleGoogleSignIn}>
                <img src={googleLogo} alt="Google Logo" className="google-logo" />
                Sign in with Google
              </button>

              {/* Add Student Login Button */}
              <button className="student-login-button" onClick={() => setShowStudentLogin(true)}>
                📗 Student Login
              </button>
            </>
          )}
        </div>

        <div className="right-panel">
          <div className="illustration">
            {error ? (
              <h2 className="error-message">{error}</h2>
            ) : message ? (
              <h2 className="success-message">{message}</h2>
            ) : (
              <>
                {isSignUp ? (
                  <>
                    <h2>Join Us</h2>
                    <img src={logo} alt="Sign Up Illustration" className="ai-illustration" />
                    <p>Sign up to explore {COMPANY_NAME}</p>
                  </>
                ) : showStudentLogin ? (
                  <>
                    <h2>Student Portal</h2>
                    <img src={logo} alt="Student Login Illustration" className="ai-illustration" />
                    <p>Welcome to {COMPANY_NAME} Student Login</p>
                  </>
                ) : showParentLogin ? (
                  <>
                    <h2>Parent Portal</h2>
                    <img src={logo} alt="Parent Login Illustration" className="ai-illustration" />
                    <p>Manage your child's account on {COMPANY_NAME}</p>
                  </>
                ) : showForgotPassword ? (
                  <>
                    <h2>Reset Password</h2>
                    <img src={logo} alt="Forgot Password Illustration" className="ai-illustration" />
                    <p>Enter your email to reset your password</p>
                  </>
                ) : (
                  <>
                    <h2>Welcome Back!</h2>
                    <img src={logo} alt="Login Illustration" className="ai-illustration" />
                    <p>Log in to continue using {COMPANY_NAME}</p>
                  </>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Login;
