// Import statements organized by functionality

// React and Hooks
import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

// Icon Imports
import { FaCompress, FaExpand, FaProjectDiagram, FaPlus } from 'react-icons/fa';

// Service Imports
import { createProject } from '../../services/projectService';
import { createVoiceflowTag } from '../../services/voiceflowService';

// CSS Import
import './RightSidebar.css';
import '../../styles/global.css';

function RightSidebar({ projects, setProjects }) {
  /* ============================================================
     State Variables
  ============================================================ */
  const [isMinimized, setIsMinimized] = useState(false);
  const [newProjectName, setNewProjectName] = useState('');
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [activeProjectId, setActiveProjectId] = useState(null);

  // React Router Hooks
  const navigate = useNavigate();
  const location = useLocation();

  /* ============================================================
     Event Handlers
  ============================================================ */

  // Toggle the right sidebar between minimized and expanded
  const toggleRightSidebar = () => {
    setIsMinimized(!isMinimized);
  };

  // Handle adding a new project
  const handleAddProject = async () => {
    if (newProjectName.trim() === '') return;

    try {
      const projectId = await createProject(newProjectName.trim());

      if (projectId) {
        setProjects([...projects, { id: projectId, projectName: newProjectName.trim() }]);
        setNewProjectName('');
        setIsPopupVisible(false);

        createVoiceflowTag(projectId)
          .then(() => {
            console.log(`Voiceflow tag created successfully for project ${projectId}`);
          })
          .catch((error) => {
            console.error('Error creating Voiceflow tag:', error);
          });
      }
    } catch (error) {
      console.error('Error creating project:', error);
    }
  };

  // Handle clicking on a project in the list
  const handleProjectClick = (projectId) => {
    setActiveProjectId(projectId);
    navigate('/ai-chat', { state: { itemId: projectId } });
  };

  /* ============================================================
     Effect Hooks
  ============================================================ */

  // Automatically minimize right sidebar on window resize
  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= 1300) {
        setIsMinimized(true);
      } else {
        setIsMinimized(false);
      }
    };

    window.addEventListener('resize', handleResize);

    // Initial check
    handleResize();

    // Cleanup on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  /* ============================================================
     Render Component
  ============================================================ */

  return (
    <div className={`right-sidebar ${isMinimized ? 'minimized' : ''}`}>
      <div className="right-sidebar-content">
        <h2>
          <FaProjectDiagram
            className="projects-icon"
            style={{ fontSize: '20px', width: '20px', height: '20px' }}
          />
          {!isMinimized && 'Projects'}
          {!isMinimized && (
            <FaPlus
              className="add-project-icon"
              onClick={() => setIsPopupVisible(true)}
              style={{ cursor: 'pointer', marginLeft: '10px' }}
            />
          )}
        </h2>
        {!isMinimized && (
          <ul>
            {projects.length === 0 ? (
              <p>
                No projects yet. Click the <FaPlus /> button to get started.
              </p>
            ) : (
              projects.map((project) => (
                <li
                  key={project.id}
                  className={project.id === activeProjectId ? 'active' : ''}
                  onClick={() => handleProjectClick(project.id)}
                >
                  {project.projectName}
                </li>
              ))
            )}
          </ul>
        )}
      </div>
      <div className="toggle-rightbar-button" onClick={toggleRightSidebar}>
        {isMinimized ? (
          <FaExpand className="minimize-icon" />
        ) : (
          <FaCompress className="minimize-icon" />
        )}
      </div>

      {/* Popup for adding a new project */}
      {isPopupVisible && (
        <div className="popup-overlay">
          <div className="popup-content">
            <h3>Quick Add New Project</h3>
            <input
              type="text"
              value={newProjectName}
              onChange={(e) => setNewProjectName(e.target.value)}
              placeholder="Enter project name"
            />
            <div className="popup-actions">
              <button onClick={handleAddProject}>Add</button>
              <button onClick={() => setIsPopupVisible(false)}>Cancel</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default RightSidebar;
