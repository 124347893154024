// src/index.js
import React from 'react';
import { createRoot } from 'react-dom/client';
import { BrowserRouter as Router } from 'react-router-dom'; // Import BrowserRouter
import App from './App';
import './index.css';
import { AuthProvider } from './AuthContext'; // Import the AuthProvider
import { VoiceProvider } from './VoiceContext'; // Import the VoiceProvider

const container = document.getElementById('root');
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <AuthProvider> {/* Wrap your App with AuthProvider */}
      <VoiceProvider> {/* Wrap your App with VoiceProvider */}
        <Router> {/* Wrap your App with BrowserRouter */}
          <App />
        </Router>
      </VoiceProvider>
    </AuthProvider>
  </React.StrictMode>
);
