// src/services/fetchLinkedStudents.js

/* ============================================================
   Import Statements
============================================================ */

import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebase'; // Adjust the path to your Firebase configuration

/* ============================================================
   Function to Fetch Linked Students
============================================================ */

/**
 * Fetches students linked to a parent based on the school name and parent email.
 * @param {string} schoolName - The name of the school.
 * @param {string} parentEmail - The email of the parent.
 * @returns {Promise<Array>} An array of linked student objects.
 */
export const fetchLinkedStudents = async (schoolName, parentEmail) => {
  try {
    // Normalize the parent email to avoid case sensitivity issues
    const normalizedParentEmail = parentEmail.trim().toLowerCase();

    // Create a query to find students linked to the provided parent email and school
    const studentQuery = query(
      collection(db, `School/${schoolName}/Students`),
      where('parentEmail', '==', normalizedParentEmail)
    );

    // Execute the query to get matching student documents
    const studentSnapshot = await getDocs(studentQuery);

    // Check if the query returned any documents
    if (studentSnapshot.empty) {
      return [];
    }

    // Map the results to extract student data (student name and other details)
    const students = studentSnapshot.docs.map((doc) => ({
      studentName: doc.id,       // Assuming the student's name is the document ID
      studentData: doc.data(),   // You can access other fields of student data here
    }));

    return students;
  } catch (error) {
    console.error('Error fetching linked students:', error);
    throw new Error('Failed to fetch linked students. Please try again.');
  }
};
